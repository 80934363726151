<template>
  <div
    style="width: 100%; height: 100%"
    id="app"
  >
    <div id="logo">
      <el-image src="kuyinLogo.png"></el-image>
    </div>
    <div
      id="ll3d"
      v-if="showLL3d"
    >
      <el-button
        @click="showLL3d = false"
        class="el-icon-close"
        style="position: absolute; right: 22%; top: 15%"
        circle
      ></el-button>
      <iframe
        width="100%"
        height="480"
        :src="ll3dSrc"
        style="width: 100%; height: 100%; background: transparent"
        frameborder="0"
        allowfullscreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        onmousewheel=""
      ></iframe>
    </div>
    <div id="right-menu">
      <ul>
        <li
          @click="switchFullScreen"
          class="menu-item"
        >
          <span class="iconfont">&#xe649;</span>
        </li>
        <li
          v-bind:class="{ music: musicEnable }"
          @click="toggleMusic()"
          class="menu-item"
        >
          <span class="iconfont">&#xe644;</span>
        </li>
        <li
          @click="showHelp = true"
          class="menu-item"
        >
          <span class="iconfont">&#xe619;</span>
        </li>
      </ul>
    </div>
    <!--    <transition name="el-fade-in">-->
    <!-- <div v-show="isLoading" id="loadScreen">
      <div>
        <el-image src="kuyinLogo.png"></el-image>
        <el-progress :percentage="loadingPercent"></el-progress>
      </div>
    </div> -->
    <!--    </transition>-->

    <div
      v-show="isLoading"
      id="loadScreen"
    >
      <div class="title"></div>
      <div
        class="progress"
        @click="handleCloseLoading"
      >
        <el-progress
          :show-text="!loadingSuccess"
          :text-inside="true"
          :stroke-width="30"
          define-back-color="#07246e"
          color="#095ae4"
          text-color="#fff"
          :percentage="loadingPercent"
        ></el-progress>
        <span
          v-if="loadingSuccess"
          class="success"
          >点击进入</span
        >
      </div>

      <!-- <div class="footer">
        <div class="text">型号：rtsmkxg</div>
        <div class="text">支持单位：次元壁（北京）科技责任有限公司</div>
      </div> -->
    </div>
    <el-dialog
      title="操作提示"
      width="70%"
      :visible.sync="showHelp"
    >
      <slot></slot>
      <el-image src="help.png"></el-image>
    </el-dialog>
    <!--    <button id="fullscreen" @click="switchFullScreen">全屏</button>-->
    <canvas
      id="canvas"
      ref="canvas"
      style="width: 100%; height: 100%"
    >
    </canvas>
  </div>
</template>

<script>
import { Game } from '@/js/game'
import { HotSportManage } from '@/js/hotSportManage'
import Vue from 'vue'
import { CustomLoadingScreen } from '@/js/customLoadingScreen'

export default {
  name: 'App',
  components: {},
  data() {
    return {
      showHelp: false,
      musicEnable: true,
      showLL3d: false,
      ll3dSrc:
        'https://www.ll3d.com/model/8a99686f5ebdc1ad015ec29d447e01bf?nobackground=true',
      isLoading: false,
      loadingPercent: 0,
      loadingSuccess: false
    }
  },
  methods: {
    switchFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
      this.$root.$el.requestFullscreen({})
    },
    toggleMusic() {
      this.musicEnable = this.game.switchMusic()
    },
    handleCloseLoading() {
      if (this.loadingSuccess) this.isLoading = false
    }
  },
  mounted() {
    window.game = this.game
    this.game = new Game(document.getElementById('canvas'))
    console.log(window.game)
    this.game.sportManage.onSportClickObservable.add((eventData) => {
      console.log(eventData)
      this.ll3dSrc = eventData.url + '?nobackground=true'
      this.showLL3d = true
    })
    this.game.loadingScreen.onDisplayObs.add((e) => (this.isLoading = true))
    // this.game.loadingScreen.onHideObs.add((e) => (this.isLoading = false))
    this.game.loadingScreen.onPercentObs.add((e) => {
      this.loadingPercent = e
      if (e === 100) {
        this.loadingSuccess = true
      }
    })
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#ll3d {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

#loadScreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#loadScreen .progress .success {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  cursor: pointer;
}

#loadScreen .el-progress .el-progress-bar__outer {
  border: 2px solid #fff;
}

#loadScreen .el-progress .el-progress-bar__inner {
  background: linear-gradient(to right, #0342e1, #34e5f9);
  text-align: center;
}
#loadScreen .el-progress .el-progress-bar__inner .el-progress-bar__innerText {
  font-size: 16px;
}

@media screen and (min-width: 700px) {
  #loadScreen {
    background: url('./assets/bg-loading-pc.webp') center no-repeat;
    background-size: cover;
  }
  #loadScreen .title {
    position: absolute;
    top: 20%;
    right: 5%;
    width: 908px;
    height: 302px;
    background: url('./assets/title-loading-pc.png') center no-repeat;
    background-size: cover;
  }
  #loadScreen .progress {
    width: 884px;
    position: absolute;
    top: 70%;
    right: 7%;
  }

  #loadScreen .footer {
    position: absolute;
    right: 5%;
    bottom: 10px;
  }
  #loadScreen .footer .text {
    margin-top: 8px;
    font-size: 28px;
    font-weight: 700;
    line-height: 73px;
    color: #fff;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5),
      -3px -3px 6px rgba(0, 0, 0, 0.5);
    letter-spacing: 3px;
    text-align: right;
  }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
  #loadScreen .title {
    width: 454px;
    height: 151px;
  }
  #loadScreen .progress {
    width: 640px;
  }
}

@media screen and (max-width: 700px) {
  #loadScreen {
    background: url('./assets/bg-loading-mobile.webp') center no-repeat;
    background-size: cover;
  }
  #loadScreen .title {
    position: absolute;
    top: 13%;
    left: 50%;
    width: 331px;
    height: 128px;
    transform: translateX(-50%);
    background: url('./assets/title-loading-mobile.png') center no-repeat;
    background-size: cover;
  }
  #loadScreen .progress {
    width: 300px;
    position: absolute;
    top: 78%;
    left: 50%;
    transform: translateX(-50%);
  }
  #loadScreen .footer {
    position: absolute;
    left: 8%;
    bottom: 2%;
  }
  #loadScreen .footer .text {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    line-height: 32px;
    color: #fff;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5),
      -3px -3px 6px rgba(0, 0, 0, 0.5);
    letter-spacing: 1px;
    text-align: left;
  }
}
/* #loadScreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loadScreen > div {


  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  height: 200px;
  margin: auto;

}

#loadScreen > div > .el-progress {
  margin: 50px;
} */

#hotSportPanel {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 800;
  pointer-events: none;
  overflow: hidden;
}

.music {
  display: block;
  animation: music 2s linear infinite;
}

@keyframes music {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

#logo {
  position: fixed;
  width: 125px;
  left: 25px;
  top: 32px;
}

.menu-item {
  padding: 5px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}

li {
  list-style-type: none;
}

#right-menu {
  position: fixed;
  display: -webkit-flex; /* Safari */
  width: 30px;
  height: 200px;
  /*z-index: 1000;*/
  top: 32px;
  right: 24px;
}

#canvas {
  /*z-index: 1;*/
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: 'iconfont'; /* Project id 3013934 */
  src: url('//at.alicdn.com/t/font_3013934_07ntygb2erri.woff2?t=1639447219663')
      format('woff2'),
    url('//at.alicdn.com/t/font_3013934_07ntygb2erri.woff?t=1639447219663')
      format('woff'),
    url('//at.alicdn.com/t/font_3013934_07ntygb2erri.ttf?t=1639447219663')
      format('truetype');
}

.iconfont {
  font-family: 'iconfont', serif !important;
  font-size: 18px;
  padding: 8px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
</style>

import {ActionManager, ExecuteCodeAction, Observable, Scene, MeshBuilder} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import {Player} from "@/js/player";

const sportList = [
    {
        "name": "B1",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20ce39184941"
    },
    {
        "name": "B2",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e8236b1aa5681"
    },
    {
        "name": "B3",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24e770d149d2"
    },
    {
        "name": "B4",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20c93931493d"
    },
    {
        "name": "B5",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20b661274934"
    },
    {
        "name": "B6",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25c5310a4a0c"
    },
    {
        "name": "B7",
        "url": "https://www.ll3d.com/model/8a99686f737a2f2201737a8b0cd00066"
    },
    {
        "name": "B8",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25c8bcec4a11"
    },
    {
        "name": "B9",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25cf28b04a16"
    },
    {
        "name": "B10",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25d032e84a19"
    },
    {
        "name": "B11",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20e9e90f494f"
    },
    {
        "name": "B12",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25c5310a4a0c"
    },
    {
        "name": "B13",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25fde54d4a2e"
    },
    {
        "name": "B14",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25c094944a0b"
    },
    {
        "name": "B15",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a97bcce4e8c"
    },
    {
        "name": "B16",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e263d40044a4a"
    },
    {
        "name": "B17",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e688aa6a053ad"
    },
    {
        "name": "B18",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6253ff5052a8"
    },
    {
        "name": "B19",
        "url": "https://www.ll3d.com/model/8a99686f765119f201765f1abe8a010e"
    },
    {
        "name": "B20",
        "url": "https://www.ll3d.com/model/8a99686f737a2f2201737af97b3a00a0"
    },
    {
        "name": "B21",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a96b34c4e88"
    },
    {
        "name": "B22",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25bd51314a09"
    },
    {
        "name": "B23",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25bb70af4a07"
    },
    {
        "name": "B24",
        "url": "https://www.ll3d.com/model/8a99686f88b82ff001898714baa4099f"
    },
    {
        "name": "B25",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e57e317e74fad"
    },
    {
        "name": "B26",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e57e317e74fad"
    },
    {
        "name": "B27",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25d68ccc4a1c"
    },
    {
        "name": "B28",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e5d289e915209"
    },
    {
        "name": "B29",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e2507128049db"
    },
    {
        "name": "B30",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e57e1cb5b4fab"
    },
    {
        "name": "B31",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e5d2790f75207"
    },
    {
        "name": "B32",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e62585f2b52b3"
    },
    {
        "name": "B33",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e689779d053bd"
    },
    {
        "name": "B34",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e689e812b53c6"
    },
    {
        "name": "B35",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e688aa6a053ad"
    },
    {
        "name": "B36",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e250dd50249dd"
    },
    {
        "name": "B37",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a95b6fa4e87"
    },
    {
        "name": "B38",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6e949e1254a2"
    },
    {
        "name": "B39",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24eed7a249d3"
    },
    {
        "name": "B40",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25ab064449f9"
    },
    {
        "name": "B41",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e7d5ab09c5604"
    },
    {
        "name": "B42",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6254c49252aa"
    },
    {
        "name": "B43",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20f5a1644955"
    },
    {
        "name": "B44",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6e680d7c549a"
    },
    {
        "name": "B45",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25a7ec5f49f5"
    },
    {
        "name": "B46",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24d6d4a249cc"
    },
    {
        "name": "B47",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25a4c94a49f1"
    },
    {
        "name": "B48",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25a2755449f0"
    },
    {
        "name": "B49",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e25a15c6e49ef"
    },
    {
        "name": "B50",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a992dab4e8f"
    },
    {
        "name": "B51",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e209633d34925"
    },
    {
        "name": "B52",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24fbf57749d8"
    },
    {
        "name": "B53",
        "url": "https://www.ll3d.com/model/8a99686f88b82ff001897233132708df"
    },
    {
        "name": "B54",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6279ea9e52cb"
    },
    {
        "name": "B55",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a931b094e81"
    },
    {
        "name": "B56",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e209d73284927"
    },
    {
        "name": "B57",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20a768ca492b"
    },
    {
        "name": "B58",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20b339a44933"
    },
    {
        "name": "B59",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20c178fb493b"
    },
    {
        "name": "B60",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20ae5c5f492c"
    },
    {
        "name": "B61",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e7d9e90fe560e"
    },
    {
        "name": "B62",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a8a893a4e7a"
    },
    {
        "name": "B63",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a883a0d4e78"
    },
    {
        "name": "B64",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a896c1e4e79"
    },
    {
        "name": "B65",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e20b7707c4935"
    },
    {
        "name": "B66",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a9460724e85"
    },
    {
        "name": "B67",
        "url": "https://www.ll3d.com/model/8a99686f737a2f2201737afc821d00a3"
    },
    {
        "name": "B68",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a944f654e82"
    },
    {
        "name": "B69",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a9d473e4e93"
    },
    {
        "name": "B70",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a9c58394e91"
    },
    {
        "name": "B71",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e9258d07e58d1"
    },
    {
        "name": "B72",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e7c024a4055c7"
    },
    {
        "name": "B73",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e68a20cfb53d4"
    },
    {
        "name": "B74",
        "url": "https://www.ll3d.com/model/8a99686f765119f201765f1dd7500110"
    },
    {
        "name": "B75",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a990d214e8e"
    },
    {
        "name": "B76",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a975c894e8a"
    },
    {
        "name": "B77",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a99cbcf4e90"
    },
    {
        "name": "B78",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e827a02525688"
    },
    {
        "name": "B79",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24b0d25549af"
    },
    {
        "name": "B80",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24d50be749c9"
    },
    {
        "name": "B81",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016ef4057a49662f"
    },
    {
        "name": "B82",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24cf991949c7"
    },
    {
        "name": "B83",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f8c75df4f1b"
    },
    {
        "name": "B84",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e5ef09fe75263"
    },
    {
        "name": "B85",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f858a6d4f0f"
    },
    {
        "name": "B86",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e5a15a2c151b3"
    },
    {
        "name": "B87",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f8f03bb4f1d"
    },
    {
        "name": "B88",
        "url": "https://www.ll3d.com/model/8a99686f88b82ff001897249885808e1"
    },
    {
        "name": "B89",
        "url": "https://www.ll3d.com/model/8a99686f737b2b0e01737e5544bb00cf"
    },
    {
        "name": "B90",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24dc420549d0"
    },
    {
        "name": "B91",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24ca517949c2"
    },
    {
        "name": "B92",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f8b73c84f19"
    },
    {
        "name": "B93",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f81300b4f0b"
    },
    {
        "name": "B94",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24fbf57749d8"
    },
    {
        "name": "B95",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6a84689e5446/detail"
    },
    {
        "name": "B96",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a6547b54e74"
    },
    {
        "name": "B97",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f8a182f4f18"
    },
    {
        "name": "B98",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24c35bd949be"
    },
    {
        "name": "B99",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24c04fbf49bd"
    },
    {
        "name": "B100",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24d79a2d49cd"
    },
    {
        "name": "B101",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f841d4e4f0e"
    },
    {
        "name": "B102",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e24d8da1049ce"
    },
    {
        "name": "B103",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4a82f8b14e77"
    },
    {
        "name": "B104",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f8918fd4f15"
    },
    {
        "name": "B105",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e4f95632e4f1e"
    },
    {
        "name": "B106",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016ea1c1c5e65a81"
    },
    {
        "name": "B107",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016ea1c082665a7e"
    },
    {
        "name": "B108",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016ea1c3d66e5a82"
    },
    {
        "name": "B109",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016ea1c52d055a85"
    },
    {
        "name": "B110",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016ea1c65c735a86"
    },
    {
        "name": "B111",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e9259be9058d2"
    },
    {
        "name": "B112",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e925bfa6658d4"
    },
    {
        "name": "B113",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e925e03a358d9"
    },
    {
        "name": "B114",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e9260e7ad58dd"
    },
    {
        "name": "B115",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e9262727658df"
    },
    {
        "name": "B116",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6ef2412054a8"
    },
    {
        "name": "B117",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6ea756da54a6"
    },
    {
        "name": "B118",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e7928c84b558e"
    },
    {
        "name": "B119",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e74d5d66a5526"
    },
    {
        "name": "B120",
        "url": "https://www.ll3d.com/model/8a99686f682833c1016e6f86c08f54b0"
    }
]

const triggerList=[
    {
        "name": "C01",
        "sportList":[
            "B1",
            "B2",
            "B3",
            "B4",
            "B5",
            "B6",
            "B7",
            "B8",
            "B9",
            "B10",
            "B11",
            "B12",
            "B13",
            "B14",
            "B15",
            "B16",
            "B17",
            "B18",
            "B19",
            "B20",
            "B21"
        ]
    },
    {
        "name": "C02",
        "sportList":[
            "B22",
            "B23",
            "B24",
            "B25",
            "B26",
            "B27",
            "B28",
            "B29",
            "B30",
            "B31",
            "B32",
            "B33",
            "B34",
            "B35",
            "B36",
            "B37",
            "B38"
        ]
    },
    {
        "name": "C03",
        "sportList":[
            "B39",
            "B40",
            "B41",
            "B42",
            "B43",
            "B44",
            "B45",
            "B46",
            "B47",
            "B48",
            "B49",
            "B50",
            "B51",
            "B52",
            "B53",
            "B54"
        ]
    },
    {
        "name": "C04",
        "sportList":[
            "B55",
            "B56",
            "B57",
            "B58",
            "B59",
            "B60",
            "B61",
            "B62",
            "B63",
            "B64",
            "B65"
        ]
    },
    {
        "name": "C05",
        "sportList":[
            "B66",
            "B67",
            "B68",
            "B69",
            "B70"
        ]
    },
    {
        "name": "C06",
        "sportList":[
            "B71"
        ]
    },
    {
        "name": "C07",
        "sportList":[
            "B72"
        ]
    },
    {
        "name": "C08",
        "sportList":[
            "B73",
            "B74",
            "B75",
            "B76",
            "B77",
            "B78"
        ]
    },
    {
        "name": "C09",
        "sportList":[
            "B79",
            "B80",
            "B81",
            "B82",
            "B83",
            "B84",
            "B85",
            "B86",
            "B87",
            "B88",
            "B89",
            "B90",
            "B91",
            "B92",
            "B93",
            "B94",
            "B95",
            "B96",
            "B97",
            "B98",
            "B99",
            "B100",
            "B101",
            "B102",
            "B103",
            "B104",
            "B105"
        ]
    },
    {
        "name": "C10",
        "sportList":[
            "B106",
            "B107",
            "B108",
            "B109",
            "B110",
            "B111",
            "B112",
            "B113",
            "B114",
            "B115"
        ]
    },
    {
        "name": "C11",
        "sportList":[
            "B116",
            "B117",
            "B118",
            "B119",
            "B120"
        ]
    },
]

export class HotSportManage {
    onSportClickObservable: Observable<{ name: string, url: string }>

    constructor(scene: Scene,player:Player) {
        this.scene = scene
        this.onSportClickObservable = new Observable()
        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('ui')
        this.hotSportList = []
        this.cameraMesh = MeshBuilder.CreateBox('cameraBox',{size:0.1},this.scene);//创建检测区域碰撞使用的Box
        this.cameraMesh.isPickable = false;
        this.cameraMesh.visibility = 0;
        this.cameraMesh.parent = player;

        // const btn = GUI.Button.CreateImageOnlyButton('gifbtn','test.gif')
        
        // btn.width = "200px"
        // btn.height = "200px"
        // this.advancedTexture.addControl(btn)
    }

    async update() {

        this.sportList = sportList
        this.sportList.forEach(item => {
            let hotSport =  GUI.Button.CreateImageOnlyButton('gifbtn','btn_img.png')
            hotSport.width = "40px"
            hotSport.height = "40px"
            // hotSport.alpha = 0.5
            hotSport.thickness = 0
            // hotSport.cornerRadius = 360
            let mesh = this.scene.getNodeByName(item.name)
            if (mesh) {
                hotSport.onPointerClickObservable.add(e => {
                    this.onSportClickObservable.notifyObservers(item)
                })
                this.advancedTexture.addControl(hotSport)
                hotSport.linkWithMesh(mesh)
            }
            hotSport.isVisible = false
            this.hotSportList.push({'name':item.name,'hotSport':hotSport})


        })
        this.triggerList = triggerList
        this.triggerList.forEach(trigger=>{
            const mesh = this.scene.getMeshByName(trigger.name)
            mesh.isVisible = false
            mesh.isPickable = false

            if(!mesh.actionManager){
                mesh.actionManager = new ActionManager(this.scene)
            }
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                trigger:ActionManager.OnIntersectionEnterTrigger,
                parameter:this.cameraMesh
            },(evt)=>{
                trigger.sportList.forEach(sportName=>{
                    this.hotSportList.filter(item=>{
                        return item.name == sportName
                    })[0].hotSport.isVisible = true
                })
            }
            ))
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                trigger:ActionManager.OnIntersectionExitTrigger,
                parameter:this.cameraMesh
            },(evt)=>{
                trigger.sportList.forEach(sportName=>{
                    this.hotSportList.filter(item=>{
                        return item.name == sportName
                    })[0].hotSport.isVisible = false
                })
            }
            ))
        })

    }
}

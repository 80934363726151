import {
    Color3,
    Mesh,
    Node,
    PBRMaterial,
    Scene,
    StandardMaterial,
    Tags,
    Texture,
    Tools,
    VideoTexture,
    SceneLoader,
    Vector3
} from "@babylonjs/core";
import axios from "axios"
import {getQueryVariable} from "@/js/tool";


async function getGalleryConfig() {
    let response = await axios.get("./template/" + getQueryVariable("tem_id") + "/config.json")
    return response.data
}

export const locateType = {
    TEXT: 1,
    IMG: 2,
    VIDEO: 3,
    AUDIO: 4,
    MODEL: 5,
}

export class GalleryManage {
    constructor(scene: Scene) {
        this.scene = scene
        this.init()
    }

    async init() {
        this.locates = []
        let config = await getGalleryConfig()
        config.send_locate.forEach(e => {
            let node = this.scene.getMeshByName(e.title)
            if (node) {
                this.locates.push(new GalleryLocate(e.title, e.locate_type, node))
            }
        })
        this.update()
    }

    update() {
        this.locates.forEach((locate) => {
            locate.update()
        })
    }
}

export class GalleryLocate {
    constructor(title: string, locate_type: number, node: Node) {
        this.title = title
        this.locate_type = locate_type
        this.node = node
    }
    getBoxMax(vec:Vector3){
        let max = vec.x>=vec.y ? vec.x :vec.y;
        max = max >= vec.z ? max : vec.z;
        return max;
    }
    getMultiBoxStandSize(Meshs){
        let meshArr = [];
        Meshs.forEach(newBox => {
            // console.log(newBox.getBoundingInfo())
            let maxNew = newBox.getBoundingInfo().boundingBox.maximumWorld
            //新模型的包围盒最小值
            let minNew = newBox.getBoundingInfo().boundingBox.minimumWorld
            //边界盒子 宽高深
            let vecNew = new Vector3(maxNew._x - minNew._x , maxNew._y - minNew._y , maxNew._z - minNew._z)
            //新模型最长边
            let standSizeNew = this.getBoxMax(vecNew)
            console.log(newBox,standSizeNew,newBox.getBoundingInfo())
            meshArr.push(standSizeNew)
        });
        let max = meshArr.reduce((a,b) => {
            return b > a ? b : a;
        })
        return max;
    }
    //传入场景内原有模型与替换模型  根据包围盒最长边的比例换算出scaling
    getBoxScaling(iniBox: Node,newBox){

        //原有模型的包围盒最大值
        let max = iniBox.getBoundingInfo().boundingBox.maximum
        //原有模型的包围盒最小值
        let min = iniBox.getBoundingInfo().boundingBox.minimum
        //边界盒子 宽高深
        let vec = new Vector3(max._x-min._x,max._y-min._y,max._z-min._z)
        //原有模型最长边
        let standSize = this.getBoxMax(vec)
        console.log(standSize)
        // //新模型的包围盒最大值
        // let maxNew = newBox.getBoundingInfo().boundingBox.maximum

        // //新模型的包围盒最小值
        // let minNew = newBox.getBoundingInfo().boundingBox.minimum
        // console.log(maxNew)
        // console.log(minNew)
        // //边界盒子 宽高深
        // let vecNew = new Vector3(maxNew._x - minNew._x , maxNew._y - minNew._y , maxNew._z - minNew._z)
        // //新模型最长边
        // let standSizeNew = this.getBoxMax(vecNew)
        // console.log(standSizeNew)

       
        let standSizeNew = this.getMultiBoxStandSize(newBox)
        console.log(standSizeNew)
        let scale = standSize/standSizeNew;//得到放大或缩小的倍数
        return scale;
    }
    async update() {
        let res = await axios.get("https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/getTemplateData", {
            params: {
                mch_id: getQueryVariable('mch_id'),
                title: this.title,
                tem_id: getQueryVariable('tem_id')

            }
        })
        let url = res.data.data.source
        let state = res.data.data.status === 1 //1为有, 2为无
        if (url) {
            url = url.replace('https://ky3d.com', 'wapapi')
        }
        this.locate_type = res.data.data.locate_type

        switch (this.locate_type) {
            case locateType.TEXT:
                break;
            case locateType.IMG:
                if (this.node instanceof Mesh && state) {
                    let mat = this.node.material
                    if (mat instanceof StandardMaterial) {
                        mat.diffuseTexture = new Texture(url, this.node._scene, false, false)
                    } else if (mat instanceof PBRMaterial) {
                        mat.albedoTexture = new Texture(url, this.node._scene, false, false)
                    }
                }
                break;
            case locateType.AUDIO:
                break;
            case locateType.VIDEO:
                // if (this.node instanceof Mesh && state && url !== "素材视频地址") {
                //     let mat = this.node.material
                //     if (mat instanceof StandardMaterial) {
                //         mat.emissiveTexture = new VideoTexture(this.title + "_video", url, this.node._scene, false, true)
                //     } else if (mat instanceof PBRMaterial) {
                //         let tex = new VideoTexture(this.title + "_video", url, this.node._scene, false, true,)
                //         mat.albedoTexture = tex
                //         mat.emissiveTexture = tex
                //         mat.emissiveIntensity = 1
                //         mat.emissiveColor = Color3.White()
                //         tex.video.muted = true
                //         tex.video.play()
                //         mat.roughness = 0.1


                //     }
                // }
                break;
            case locateType.MODEL:
          
                //原有模型
                let node = this.node
                console.log(node)
                //原有模型的绝对定位
                let absolutePosition = this.node.getAbsolutePosition()
                
                if(url){
                    this.node.isVisible = false

                    const { meshes } = await SceneLoader.ImportMeshAsync("", "", url, this.node._scene);
                    let scaling = this.getBoxScaling(node,meshes)
     
                    meshes.forEach((item,k) => {
                        if(k>0){
                            item.setAbsolutePosition(absolutePosition)
                            item.scaling.x = scaling;
                            item.scaling.y = scaling;
                            item.scaling.z = scaling;
                        }
                    });

                }    
            break;

        }
    }
}
